$w-mobile-menu: "650px";
$w-narrow: 500px;
$w-smallest: 400px;

body {
	position: relative;

	&.dimmed {
		background-color: gray;
	}
}
#banner {
	.logo img {
		width: 100px;
	}
	
	@media(max-width: $w-mobile-menu) {
		h2 {
			font-size: 2em;
		}
	}

	@media(max-width: $w-narrow) {
		.logo {
			margin: 0 20px 1.3em 0;
			
			img {
					width: 80px;
			}
		}

		h2 {
			font-size: 1.5em;
		}
		
		p {
			font-size: 0.8em;
		}
	}
	
	@media(max-width: $w-smallest) {
		.logo {
			margin: 0 5px 1.3em 0;
			
			img {
				width: 60px;
			}
		}
	}
	
	
}
#hb {
	display: none;
	width: 30px;
	position: fixed;
	top: 5px;
	right: 5px;

	@media (max-width: $w-mobile-menu) {
		display: block;
	}

	@media (max-width: $w-narrow) {
		#hb {
			display: block;
		}
	}
}
nav {
	@media (max-width: $w-mobile-menu) {
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0);
		margin: 20px auto;
	}

	@media (max-width: $w-narrow) {
		left: 10px;
		transform: none;
	}
}

.ui {
	&.vertical.menu {
		margin-top: -1em;
		z-index: 99;

		.item {
			color: black;
			text-align: left;
		}
	}

	&.menu {
		background: none;
		border: none;
		box-shadow: none;

		 .item {
			color: white;
			border: none;
		}
		 .item:before {
			background: none;
		}
		 a.item:hover {
			background: none;
		}
	}
}


h1 {
	clear: both;
	margin: 20px 0;
	font-size: 200%;

	@media (max-width: $w-narrow) {
		font-size: 150%;
	}
}
main {
	margin: 0 40px;
	min-height: 1000px;
}

#one.wrapper .inner {
	width: 95%;
} 

.wrapper:not(#one) .content {
	padding: 0 40px;

	@media (max-width: $w-narrow) {
		padding: 0 20px;
	}

	@media (max-width: $w-smallest) {
		font-size: 18px;
	}
}
.content {
	font-size: 125%;
}
#footer {
	.inner {
		width: 80%;
		padding-top: 2em;
	}
	p {
		font-size: 125%;
	}
	h2.major {
		text-align: right;
		font-size: 175%;
		border: none;
		padding-bottom: 0;
	}
}